.app__video {
    display: flex;
    height: 100vh;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}

.app__video video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.app__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.30);
}

.app__video-overlay_circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid var(--color-golden);
    cursor: pointer;
}