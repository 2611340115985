.app__laurels {
    padding: 4rem 10rem;
}

.app__laurels_awards {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.app__laurels_awards-card {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.app__laurels_awards-card_content p {
    font-size: 17px;
}

@media screen and (max-width: 1400px) {
    .app__laurels_awards {
        display: flex;
        flex-direction: column;
    }

    .app__laurels_image img {
        width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .app__laurels {
        padding: 3rem;
    }

    .app__laurels_info h1 {
        letter-spacing: 0.04rem;
    }

    .app__laurels_awards {
        margin-top: 2rem;
    }

    .app__laurels_awards-card {
        min-width: 100%;
        margin: 0;
    }

    .app__laurels_image {
        margin-top: 1rem;
    }

}